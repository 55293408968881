import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/armeria/armeria/site/src/layouts/release-notes.tsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Tip = makeShortcode("Tip");
const ThankYou = makeShortcode("ThankYou");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p {...{
      "className": "date"
    }}>{`24th March 2022`}</p>


    <h2 {...{
      "id": "-highlights",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#-highlights",
        "aria-label": " highlights permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`🌸 Highlights`}</h2>
    <ul>
      <li parentName="ul">{`gRPC health check protocol`}</li>
      <li parentName="ul">{`Multipart file uploads in annotated services`}</li>
      <li parentName="ul">{`Preliminary GraphQL support in `}<a parentName="li" {...{
          "href": "type://DocService:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/docs/DocService.html"
        }}>{`type://DocService`}</a>{` `}</li>
      <li parentName="ul">{`Refactored DNS resolver cache with higher hit ratio and reduced traffic`}</li>
      <li parentName="ul">{`New metrics that help you get alerts before your TLS certificates are expired`}</li>
    </ul>
    <h2 {...{
      "id": "-new-features",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#-new-features",
        "aria-label": " new features permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`🌟 New features`}</h2>
    <ul>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "type://Server:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/Server.html"
          }}>{`type://Server`}</a>{` now registers two gauges about TLS certificate expiry, so you can get alerts before
the certificates are expired. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/4075"
          }}>{`#4075`}</a>{` `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/4082"
          }}>{`#4082`}</a></p>
        <ul parentName="li">
          <li parentName="ul"><inlineCode parentName="li">{`armeria_server_certificate_validity`}</inlineCode>
            <ul parentName="li">
              <li parentName="ul"><inlineCode parentName="li">{`1`}</inlineCode>{` if certificate is in validity period. `}<inlineCode parentName="li">{`0`}</inlineCode>{` otherwise.`}</li>
            </ul>
          </li>
          <li parentName="ul"><inlineCode parentName="li">{`armeria_server_certificate_validity_days`}</inlineCode>
            <ul parentName="li">
              <li parentName="ul">{`The number of days before certificate expires, or `}<inlineCode parentName="li">{`-1`}</inlineCode>{` if expired.`}</li>
            </ul>
          </li>
        </ul>
      </li>
      <li parentName="ul">
        <p parentName="li">{`You can now specify a `}<inlineCode parentName="p">{`File`}</inlineCode>{` or `}<inlineCode parentName="p">{`Path`}</inlineCode>{` parameter in your annotated service method to handle multipart file
uploads. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/3578"
          }}>{`#3578`}</a>{` `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/4061"
          }}>{`#4061`}</a></p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`Server
  .builder()
  .annotatedService(new Object() {
    @Post("/upload")
    @Consumes("multipart/form-data")
    public String upload(@Param File file) {
      return "Successfully uploaded a file to " + file;
    }
  })
  .build();
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li">{`You can now use the `}<inlineCode parentName="p">{`||`}</inlineCode>{` (logical OR) operator in parameter and header predicate expressions. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/4116"
          }}>{`#4116`}</a>{` `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/4138"
          }}>{`#4138`}</a></p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`// "Hi!" for /greet?casual or /greet?casual=true
// "Hello!" for /greet or /greet?casual=false
Server
  .builder()
  .route()
    .get("/greet")
    .matchesParam("casual || casual=true") // 👈👈👈
    .build((ctx, req) -> HttpResponse.of("Hi!"))
  .route()
    .get("/greet")
    .matchesParam("!casual || casual!=true") // 👈👈👈
    .build(ctx, req) -> HttpResponse.of("Hello!"))  
`}</code></pre>
        <Tip mdxType="Tip">
          <p parentName="li">{`You can use the same syntax in `}<a parentName="p" {...{
              "href": "/docs/server-annotated-service"
            }}>{`annotated services`}</a>{`
using `}<a parentName="p" {...{
              "href": "type://@MatchesParam:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/annotation/MatchesParam.html"
            }}>{`type://@MatchesParam`}</a>{` and `}<a parentName="p" {...{
              "href": "type://@MatchesHeader:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/annotation/MatchesHeader.html"
            }}>{`type://@MatchesHeader`}</a>{`.`}</p>
        </Tip>
      </li>
      <li parentName="ul">
        <p parentName="li">{`You can now enable `}<a parentName="p" {...{
            "href": "https://github.com/grpc/grpc/blob/master/src/proto/grpc/health/v1/health.proto"
          }}>{`gRPC health check protocol`}</a>{`
with `}<a parentName="p" {...{
            "href": "type://GrpcHealthCheckService:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/grpc/GrpcHealthCheckService.html"
          }}>{`type://GrpcHealthCheckService`}</a>{` `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/3146"
          }}>{`#3146`}</a>{` `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/3963"
          }}>{`#3963`}</a></p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`Server
  .builder()
  .service(
    GrpcService
      .builder()
      .addService(new MyGrpcService())
      .enableHealthCheckService(true) // 👈👈👈
      // or customize:
      // .addService(GrpcHealthCheckService.of(...))
      .build()
  )
  .build()
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "type://DocService:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/docs/DocService.html"
          }}>{`type://DocService`}</a>{` now supports `}<a parentName="p" {...{
            "href": "type://GraphqlService:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/graphql/GraphqlService.html"
          }}>{`type://GraphqlService`}</a>{` with CodeMirror auto-completion. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/3706"
          }}>{`#3706`}</a>{` `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/4023"
          }}>{`#4023`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`You can now configure the `}<a parentName="p" {...{
            "href": "type://EndpointGroup:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/endpoint/EndpointGroup.html"
          }}>{`type://EndpointGroup`}</a>{` implementations based on `}<a parentName="p" {...{
            "href": "type://DynamicEndpointGroup:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/endpoint/DynamicEndpointGroup.html"
          }}>{`type://DynamicEndpointGroup`}</a>{`
to allow or disallow empty endpoints. This can be useful when you want to avoid the situation where
the endpoint group becomes empty due to misconfiguration. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/3952"
          }}>{`#3952`}</a>{` `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/3958"
          }}>{`#3958`}</a></p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`EndpointGroup endpointGroup =
  DnsAddressEndpointGroup
    .builder("example.com")
    .allowEmptyEndpoints(false) // 👈👈👈
    .build();
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li">{`You can now configure the timeout of DNS sub-queries with `}<inlineCode parentName="p">{`queryTimeoutMillisForEachAttempt()`}</inlineCode>{`. Previously,
you were only able to specify the timeout for the entire DNS resolution process, which can consist of more
than one DNS query. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/2940"
          }}>{`#2940`}</a>{` `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/4133"
          }}>{`#4133`}</a>{` `}</p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`DnsAddressEndpointGroup
  .builder("armeria.dev")
  .queryTimeoutMillisForEachAttempt(1000) // 👈👈👈
  .queryTimeoutMillis(5000)
  .build();
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Armeria now has the global shared DNS cache by default for higher hit ratio and less DNS traffic.
You can also build and share a custom DNS cache across multiple resolvers. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/2940"
          }}>{`#2940`}</a>{` `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/4133"
          }}>{`#4133`}</a></p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`DnsCache dnsCache = 
  DnsCache
    .builder()
    .ttl(60, 3600)
    .negativeTtl(600)
    .build();

EndpointGroup endpointGroup = 
  DnsAddressEndpointGroup
    .builder("armeria.dev")
    .dnsCache(dnsCache) // 👈👈👈
    .build();

ClientFactory factory =
  ClientFactory
    .builder()
    .domainNameResolverCustomizer(builder -> {
      builder.dnsCache(dnsCache); // 👈👈👈
    })
    .build();

WebClient client =
  WebClient
    .builder(SessionProtocol.HTTPS, endpointGroup) // 👈👈👈
    .factory(factory) // 👈👈👈
    .build();         
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Failed search domain queries are now cached according to the negative TTL configuration. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/2940"
          }}>{`#2940`}</a>{` `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/4133"
          }}>{`#4133`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`You can now specify a `}<a parentName="p" {...{
            "href": "type://SuccessFunction:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/SuccessFunction.html"
          }}>{`type://SuccessFunction`}</a>{` when constructing a client or server, and let all decorators
use it for determining whether a request was successful or not. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/4101"
          }}>{`#4101`}</a></p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`Server
  .builder()
  .successFunction((ctx, log) -> { // 👈👈👈
    // Treat only '200 OK' and '204 No Content' as success. 
    switch (log.responseStatus().code()) {
      case 200:
      case 204:
        return true;
      default:
        return false;
    }
  })
  .decorator(LoggingService.newDecorator())
  .decorator(MetricCollectingService.newDecorator())
  ...

WebClient
  .builder()
  .successFunction(...) // 👈👈👈
  .decorator(LoggingClient.newDecorator())
  .decorator(MetricCollectingClient.newDecorator())
  .build();
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li">{`You can now specify different sampling ratio (or `}<a parentName="p" {...{
            "href": "type://Sampler:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/util/Sampler.html"
          }}>{`type://Sampler`}</a>{`) for successful and failed requests
when using `}<a parentName="p" {...{
            "href": "type://LoggingClient:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/logging/LoggingClient.html"
          }}>{`type://LoggingClient`}</a>{` and `}<a parentName="p" {...{
            "href": "type://LoggingService:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/logging/LoggingService.html"
          }}>{`type://LoggingService`}</a>{` `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/3666"
          }}>{`#3666`}</a>{` `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/4101"
          }}>{`#4101`}</a></p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`LoggingService
  .builder()
  // Log at 10% sampling ratio if successful.
  .successSamplingRate(0.1)
  // Log all failure.
  .failureSamplingRate(1.0)
  .newDecorator()
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li">{`You can now customize how `}<a parentName="p" {...{
            "href": "type://LoggingClient:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/logging/LoggingClient.html"
          }}>{`type://LoggingClient`}</a>{` and `}<a parentName="p" {...{
            "href": "type://LoggingService:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/logging/LoggingService.html"
          }}>{`type://LoggingService`}</a>{` determines log level
more flexibly. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/3972"
          }}>{`#3972`}</a></p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`LoggingService
  .builder()
  // Log at INFO if under /important. DEBUG otherwise.
  .requestLogLevelMapper(log -> {
    if (log.requestHeaders().path().startsWith("/important/")) {
      return LogLevel.INFO;
    } else {
      return LogLevel.DEBUG;
    }
  })
  // Log at INFO if 200 OK.
  .responseLogLevel(HttpStatus.OK, LogLevel.INFO)
  // Log at WARN if 5xx.
  .responseLogLevel(HttpStatusClass.SERVER_ERROR, LogLevel.WARN)
  .newDecorator()    
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li">{`You can now specify a customizer that customizes a `}<a parentName="p" {...{
            "href": "type://ClientRequestContext:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/ClientRequestContext.html"
          }}>{`type://ClientRequestContext`}</a>{` when building a client. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/4075"
          }}>{`#4075`}</a>{` `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/4082"
          }}>{`#4082`}</a></p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`WebClient client =
  WebClient
    .builder()
    .contextCustomizer(ctx -> { // 👈👈👈
      String userId = MyUserContext.current().getId();
      ctx.setAttr(USER_ID, userId);
    })
    .build();
`}</code></pre>
        <p parentName="li">{`For instance, you could manually propagate a Brave thread-local `}<inlineCode parentName="p">{`TraceContext`}</inlineCode>{` using it:`}</p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`Tracing reactorTracing = ...;
Tracing requestContextTracing =
  Tracing
    .newBuilder()
    .currentTraceContext(RequestContextCurrentTraceContext.ofDefault())
    .build();

WebClient
  .builder()
  .contextCustomizer(TraceContextPropagation.inject(() -> {
    // Propagate Reactor's TraceContext to Armeria's TraceContext.
    return reactorTracing.currentTraceContext().get();
  })
  .decorator(BraveClient.newDecorator(requestContextTracing))
  .build();
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li">{`You can now write the content of `}<a parentName="p" {...{
            "href": "type://StreamMessage:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/stream/StreamMessage.html"
          }}>{`type://StreamMessage`}</a>{` into a file using
`}<a parentName="p" {...{
            "href": "type://StreamMessage#writeTo(Function,Path,OpenOption):https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/stream/StreamMessage.html#writeTo(java.util.function.Function,java.nio.file.Path,java.nio.file.OpenOption...)"
          }}>{`type://StreamMessage#writeTo(Function,Path,OpenOption)`}</a>{` `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/4048"
          }}>{`#4048`}</a>{` `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/4130"
          }}>{`#4130`}</a></p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`SplitHttpResponse res =
  WebClient
    .of()
    .get("https://example.com/large_file")
    .split();
StreamMessage<HttpData> body = res.body();
body.writeTo( // 👈👈👈
  Function.identity(),
  Path.of("/tmp", "large_file")
);
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li">{`You can now convert a `}<a parentName="p" {...{
            "href": "type://StreamMessage:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/stream/StreamMessage.html"
          }}>{`type://StreamMessage`}</a>{` into an `}<inlineCode parentName="p">{`InputStream`}</inlineCode>{` using
`}<a parentName="p" {...{
            "href": "type://StreamMessage#toInputStream(Function):https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/stream/StreamMessage.html#toInputStream(java.util.function.Function)"
          }}>{`type://StreamMessage#toInputStream(Function)`}</a>{` `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/4059"
          }}>{`#4059`}</a></p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`StreamMessage<HttpData> body = ...;
BufferedReader in = new BufferedReader(
  new InputStreamReader(
    body.toInputStream(Function.identity()), // 👈👈👈
    "UTF-8"
  )
);
for (;;) {
  String line = in.readLine();
  if (line == null) break;
  System.out.println(line);
}
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li">{`You can now use `}<a parentName="p" {...{
            "href": "type://StreamMessage#mapParallel(Function,int):https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/stream/StreamMessage.html#mapParallel(java.util.function.Function,int)"
          }}>{`type://StreamMessage#mapParallel(Function,int)`}</a>{` to modify a stream using an async operation
with a configurable concurrency limit. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/4031"
          }}>{`#4031`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`You can now decode a `}<a parentName="p" {...{
            "href": "type://StreamMessage:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/stream/StreamMessage.html"
          }}>{`type://StreamMessage`}</a>{` into another using `}<a parentName="p" {...{
            "href": "type://StreamMessage#decode(StreamDecoder):https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/stream/StreamMessage.html#decode(com.linecorp.armeria.common.stream.StreamDecoder)"
          }}>{`type://StreamMessage#decode(StreamDecoder)`}</a>{`
which was previously possible only for `}<a parentName="p" {...{
            "href": "type://HttpMessage:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/HttpMessage.html"
          }}>{`type://HttpMessage`}</a>{`. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/4147"
          }}>{`#4147`}</a>{` `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/4148"
          }}>{`#4148`}</a>{` `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/4152"
          }}>{`#4152`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`You can now give Armeria a performance optimization hint by specifying if your service is unary,
request-streaming, response-streaming or bidi-streaming by implementing `}<a parentName="p" {...{
            "href": "type://HttpService#exchangeType()"
          }}>{`type://HttpService#exchangeType()`}</a>{` `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/3956"
          }}>{`#3956`}</a></p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`class MyUnaryService implements HttpService {
  @Override
  public HttpResponse serve(ServiceRequestContext ctx, HttpRequest req) {
    return HttpResponse.from(
      req
        .aggregate() // Always aggregate
        .thenApply(aggregatedReq -> {
          HttpResponse.of( // Never streaming
            "You sent %d bytes.",
            aggregatedReq.content().length()
          );
        })
    );
  }

  // Tells Armeria to assume non-streaming requests and responses.
  @Override
  public ExchangeType exchangeType() {
    return ExchangeType.UNARY; // 👈👈👈
  }
}
`}</code></pre>
      </li>
    </ul>
    <h2 {...{
      "id": "-improvements",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#-improvements",
        "aria-label": " improvements permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`📈 Improvements`}</h2>
    <ul>
      <li parentName="ul">{`Spring Boot `}<inlineCode parentName="li">{`WebServerException`}</inlineCode>{` message is now more user-friendly. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/4146"
        }}>{`#4146`}</a></li>
    </ul>
    <h2 {...{
      "id": "️-bug-fixes",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%EF%B8%8F-bug-fixes",
        "aria-label": "️ bug fixes permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`🛠️ Bug fixes`}</h2>
    <ul>
      <li parentName="ul">{`A response with status code `}<inlineCode parentName="li">{`408`}</inlineCode>{` doesn't trigger a `}<inlineCode parentName="li">{`NullPointerException`}</inlineCode>{` anymore, even if its reason phrase
is not exactly `}<inlineCode parentName="li">{`Request Timeout`}</inlineCode>{`. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/4165"
        }}>{`#4165`}</a>{` `}</li>
      <li parentName="ul">{`Failed search domain queries are now cached properly according to the negative TTL configuration. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2940"
        }}>{`#2940`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/4133"
        }}>{`#4133`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "type://GrpcService:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/grpc/GrpcService.html"
        }}>{`type://GrpcService`}</a>{` now logs `}<a parentName="li" {...{
          "href": "type://RequestOnlyLog#requestContent():https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/logging/RequestOnlyLog.html#requestContent()"
        }}>{`type://RequestOnlyLog#requestContent()`}</a>{` for invalid requests. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/4128"
        }}>{`#4128`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "type://DocService:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/docs/DocService.html"
        }}>{`type://DocService`}</a>{` doesn't crash the whole Armeria service anymore when `}<inlineCode parentName="li">{`.proto`}</inlineCode>{` files have comments
on service options or extensions. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/4123"
        }}>{`#4123`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/4127"
        }}>{`#4127`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "type://GraphqlService:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/graphql/GraphqlService.html"
        }}>{`type://GraphqlService`}</a>{` doesn't throw a `}<inlineCode parentName="li">{`ClassCastException`}</inlineCode>{` anymore when `}<inlineCode parentName="li">{`query`}</inlineCode>{` or `}<inlineCode parentName="li">{`operationName`}</inlineCode>{` are not
a string. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/4104"
        }}>{`#4104`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/4168"
        }}>{`#4168`}</a>{`  `}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "type://FileService:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/file/FileService.html"
        }}>{`type://FileService`}</a>{` doesn't choose a `}<inlineCode parentName="li">{`.br`}</inlineCode>{` file for generating decompressed content anymore when Brotli
is not available. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/4119"
        }}>{`#4119`}</a></li>
      <li parentName="ul">{`You no longer see an `}<a parentName="li" {...{
          "href": "type://HttpResponseException:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/HttpResponseException.html"
        }}>{`type://HttpResponseException`}</a>{` or `}<a parentName="li" {...{
          "href": "type://HttpStatusException:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/HttpStatusException.html"
        }}>{`type://HttpStatusException`}</a>{` from built-in services. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/4056"
        }}>{`#4056`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/4117"
        }}>{`#4117`}</a>
        <ul parentName="li">
          <li parentName="ul">{`Thanks to this fix, you can now mutate a redirect response from `}<a parentName="li" {...{
              "href": "type://FileService:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/file/FileService.html"
            }}>{`type://FileService`}</a>{` in your decorator
using `}<a parentName="li" {...{
              "href": "type://HttpResponse#mapHeaders(Function):https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/HttpResponse.html#mapHeaders(java.util.function.Function)"
            }}>{`type://HttpResponse#mapHeaders(Function)`}</a></li>
        </ul>
      </li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "type://RampingUpEndpointWeightSelector"
        }}>{`type://RampingUpEndpointWeightSelector`}</a>{` doesn't raise a `}<inlineCode parentName="li">{`NoSuchElementException`}</inlineCode>{` anymore when endpoints
are replaced completely. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/3776"
        }}>{`#3776`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/4102"
        }}>{`#4102`}</a></li>
    </ul>
    <h2 {...{
      "id": "️-deprecations",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%EF%B8%8F-deprecations",
        "aria-label": "️ deprecations permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`🏚️ Deprecations`}</h2>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`samplingRate`}</inlineCode>{` property of `}<a parentName="li" {...{
          "href": "type://@LoggingDecorator:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/annotation/decorator/LoggingDecorator.html"
        }}>{`type://@LoggingDecorator`}</a>{` has been deprecated in favor of `}<inlineCode parentName="li">{`successSamplingRate`}</inlineCode>{`
and `}<inlineCode parentName="li">{`failureSamplingRate`}</inlineCode>{`.`}</li>
      <li parentName="ul">{`The `}<inlineCode parentName="li">{`successFunction()`}</inlineCode>{` builder methods that require a `}<inlineCode parentName="li">{`BiPredicate`}</inlineCode>{` has been deprecated in favor of
`}<a parentName="li" {...{
          "href": "type://SuccessFunction:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/SuccessFunction.html"
        }}>{`type://SuccessFunction`}</a>{` that's specified when constructing a client or server.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`dnsServerAddressStreamProvider()`}</inlineCode>{` has been deprecated in favor of `}<inlineCode parentName="li">{`serverAddressStreamProvider()`}</inlineCode>{` in the
DNS-related builders.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`disableDnsQueryMetrics()`}</inlineCode>{` has been deprecated in favor of `}<inlineCode parentName="li">{`enableDnsQueryMetrics()`}</inlineCode>{` in the DNS-related
builders.`}</li>
    </ul>
    <h2 {...{
      "id": "️-breaking-changes",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%EF%B8%8F-breaking-changes",
        "aria-label": "️ breaking changes permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`☢️ Breaking changes`}</h2>
    <ul>
      <li parentName="ul">{`The experimental `}<inlineCode parentName="li">{`HttpDecoder`}</inlineCode>{` API has been replaced with `}<a parentName="li" {...{
          "href": "type://StreamDecoder:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/encoding/StreamDecoder.html"
        }}>{`type://StreamDecoder`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/4147"
        }}>{`#4147`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/4152"
        }}>{`#4152`}</a></li>
    </ul>
    <h2 {...{
      "id": "-dependencies",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#-dependencies",
        "aria-label": " dependencies permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`⛓ Dependencies`}</h2>
    <ul>
      <li parentName="ul">{`Bucket4j 7.0.0 → 7.3.0`}</li>
      <li parentName="ul">{`Curator 5.2.0 → 5.2.1`}</li>
      <li parentName="ul">{`Dropwizard Metrics 4.2.7 → 4.2.9`}</li>
      <li parentName="ul">{`gRPC-Java 1.43.2 → 1.45.0`}</li>
      <li parentName="ul">{`Jackson 2.13.1 → 2.13.2`}</li>
      <li parentName="ul">{`java-jwt 3.18.3 → 3.19.0`}</li>
      <li parentName="ul">{`Jetty 9.4.44 → 9.4.45`}</li>
      <li parentName="ul">{`Graphql-Java 17.3 → 18.0`}</li>
      <li parentName="ul">{`Logback 1.2.10 → 1.2.11`}</li>
      <li parentName="ul">{`Micrometer 1.8.2 → 1.8.4`}</li>
      <li parentName="ul">{`Netty 4.1.73 → 4.1.75`}
        <ul parentName="li">
          <li parentName="ul">{`io_uring 0.0.11 → 0.0.13`}</li>
        </ul>
      </li>
      <li parentName="ul">{`Prometheus 0.14.1 → 0.15.0`}</li>
      <li parentName="ul">{`Reactor 3.4.14 → 3.4.16`}
        <ul parentName="li">
          <li parentName="ul">{`Reactor Kotlin 1.1.5 → 1.1.6`}</li>
        </ul>
      </li>
      <li parentName="ul">{`RxJava 3.1.3 → 3.1.4`}</li>
      <li parentName="ul">{`Sangria 2.1.6 → 3.0.0`}</li>
      <li parentName="ul">{`ScalaPB 0.11.8 → 0.11.10`}</li>
      <li parentName="ul">{`scala-collection-compat 2.6.0 → 2.7.0`}</li>
      <li parentName="ul">{`SLF4J 1.7.34 → 1.7.36`}</li>
      <li parentName="ul">{`Spring 5.3.15 → 5.3.17`}</li>
      <li parentName="ul">{`Spring Boot 2.6.3 → 2.6.5`}</li>
    </ul>
    <h2 {...{
      "id": "-thank-you",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#-thank-you",
        "aria-label": " thank you permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`🙇 Thank you`}</h2>
    <ThankYou usernames={['Dogacel', 'Jimexist', 'anuraaga', 'doppany', 'ghkim3221', 'heowc', 'ikhoon', 'injae-kim', 'jrhee17', 'klurpicolo', 'kojilin', 'lucasamoroso', 'minwoox', 'pine', 'sleeplesslord', 'ta7uw', 'tobias-', 'trustin', 'wooseongshin', 'wooyeong']} mdxType="ThankYou" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      